import { Email } from './entities/email.entity'
import apiClient from './utils/AxiosClient'
// import { ProcessMessageCallback, fetchStream } from './utils/StreamProcessor'

export const API = {
  getAllEmails: async (): Promise<Email[] | undefined> => {
    let url = `/emails`
    const response: any = await apiClient.get(url)
    return response.data
  },

  dummyCall: async (delay: number) => new Promise(resolve => setTimeout(() => resolve('ok'), delay))
  // const result1 = await APIQueue.getInstance().enqueue(() => API.dummyCall(2000))
}
