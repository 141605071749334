import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './authConfig'

// Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css'

const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    {/* <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider> */}
    <App />
  </React.StrictMode>
)
