import React from 'react'
import { API } from './apis/API'
import { Email } from './apis/entities/email.entity'
import './EmailList.css' // Import CSS file for styling

export const EmailList: React.FC = () => {
  const [emails, setEmails] = React.useState<Email[]>([])

  // add a useEffect hook to fetch emails
  React.useEffect(() => {
    API.getAllEmails().then(emails => setEmails(emails || []))
  }, [])

  return (
    <div className="email-list-grid">
      {emails.map(email => (
        <div className="email-row" key={email.id}>
          <div className="email-content">
            <div className="email-subject">{email.subject}</div>
            <div className="email-body" style={{ whiteSpace: 'pre-wrap' }}>
              {email.bodyText}
            </div>
          </div>
          <div className="email-body" style={{ whiteSpace: 'pre-wrap' }}>
            {email.lastMessage}
          </div>
          <div className="email-status">status</div>
          <div className="email-status">action</div>
        </div>
      ))}
    </div>
  )
}
