import React from 'react'
import { EmailList } from './EmailList'

const App: React.FC = () => {
  return (
    <div>
      <EmailList />
    </div>
  )
}

export default App
